import React from 'react'
import { Link, navigate } from 'gatsby'
import Layout from '../components/layout'
import MainWrap from '../components/mainwrap'
import H1 from '../components/h1'
import Button from '../components/button'
import FrontPageSplit from '../components/frontpagesplit'
import Img from 'gatsby-image'
import {Introduction, H2, ImgWrap, CenterContent, TermsParagraph} from '../styles/pages/frontpage'
import useCampaignQuery from "../graphql/queries"
import { Margin } from 'styled-components-spacing'

export default function IndexPage() {

const { premie, iphonehand, sanityCampaign } = useCampaignQuery()

    return(
  <Layout>
    <FrontPageSplit>
    <Introduction>
        <div className="inner">
            <H2><span>Hold på spenningen</span> <br /><span>med mobil bingo!</span></H2>
        </div>
        <div className="phone-img">
            <ImgWrap>
                <Img fixed={iphonehand.childImageSharp.fixed} alt="Hånd som holder en mobiltelefon"  style={{ opacity: '0' }}/>
            </ImgWrap>
        </div>
    </Introduction>
    <MainWrap center>
    <CenterContent>
        <H1 title="Svar på tre spørsmål om<br/> Spilleriet og bli med i trekningen av Lenovo nettbrett og iPhone 12" noborder={true}></H1>
        <Margin bottom={5}>
            <Button title="Start quiz" handleClick={ () => { navigate("/quiz") }} answers={null} outline={true}></Button>
        </Margin>
        <ImgWrap>
            <Img fixed={premie.childImageSharp.fixed} alt="" />
        </ImgWrap>
        <p style={{marginTop: '25px'}}>Vi trekker en ny vinner av Lenovo Tab M10 hver fredag frem til ??. Iphone 12 trekkes ??</p>
        
        </CenterContent>
        <TermsParagraph>
            <Link to="/terms/">Konkurransebetingelser</Link>
            {sanityCampaign.winners.length > 0 &&
            <>&nbsp;<Link to="/winners/">Se vinnere</Link></>
            }
        </TermsParagraph>
    </MainWrap>
    </FrontPageSplit>
  </Layout>
)}




