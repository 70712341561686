import React from 'react'
import PropTypes from 'prop-types'
import { FrontPageSplitStyles } from '../styles/pages/frontpage'

export default function FrontPageSplit({children}) {
    return(
    <FrontPageSplitStyles>
        {children}
    </FrontPageSplitStyles>
    )
}

FrontPageSplit.propTypes = {
    children: PropTypes.array.isRequired,
}
