import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';


export const ImgWrap = styled.div`
.gatsby-image-wrapper {
        vertical-align: bottom;
        picture > img {
            position: relative !important;
        }
    }
`

export const CenterContent = styled.div`
display: flex;
align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
`

export const H2 = styled.h2`
    font-family: ${props => props.theme.fonts};
    font-weight: 600;
    font-size: 30px;
    line-height: 1.1;
    position: relative;
    align-self: flex-start;
      span {
          position: relative;
          opacity: 0;
          display: inline-block;
          animation: animateIn 600ms ease-in-out forwards;
          &:nth-of-type(2) {
              animation-delay: 200ms;
          }
      }
    ${tw`mb-4`};
    ${breakpoint('md')`
        font-size: 40px;
    `}
`

export const Introduction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  .inner {
    padding-top: 100px;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    flex: 1;
  }
  .phone-img {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: auto;
      
.gatsby-image-wrapper {
    animation: animateInImg 800ms ease-in-out forwards;
    animation-delay: 200ms;
    max-height: calc(100vh - 250px);
    width: auto;
height: auto;
${breakpoint('md')`
    max-width: 540px;
`}
}




  @keyframes animateInImg {
      from {
          opacity: 0;
          transform: translateY(100px);
      }
      to {
          opacity: 1;
          transform: translateY(0px) scale(1);
      }
  }

  @keyframes animateIn {
      from {
          opacity: 0;
          transform: translateY(10px) scale(0.8);
      }
      to {
          opacity: 1;
          transform: translateY(0px) scale(1);
      }
  }
 
}`


export const TermsParagraph = styled.p`
  margin-top: auto;
  font-size: 13px;
  
`

export const FrontPageSplitStyles = styled.div`
display: flex;
min-height: 100vh;
width: 100%;
flex-direction: column;

  @media (min-width: 767px) {
      flex-direction: row;
      > div {
          flex: 1;
          max-width: 50%;
          height: 100vh;
      }
      > div:last-of-type {
          margin: unset;
          overflow-y: auto;
          margin-left: auto !important;
          display: flex;
          flex-direction: column;
          padding-top: 100px;
          > div > div:first-of-type {
              padding: 0;
          }
      }
  }
`